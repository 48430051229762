export default class DfProvince {
  private _name: string;
  private _code: string;
  private _region: any = {};

  constructor(name: string, code: string) {
    this._name = name;
    this._code = code;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get code(): string {
    return this._code;
  }

  set code(code: string) {
    this._code = code;
  }

  get region(): any {
    return this._region;
  }

  set region(region: any) {
    this._region = region;
  }
}
