import UrlBuilderInterface from "./interfaces/url-builder-interface";
import { injectable } from "inversify";
import Utils from "@/vue/infrastructure/utils/helpers";

@injectable()
class ApiUrlRequestBuilder implements UrlBuilderInterface {
  private static readonly BASE_API_PATH: string = "api";

  public getEndPoint(endpoint: string): string {
    endpoint = endpoint ? `/${endpoint}` : "";
    return `${Utils.getEndpointContextPath()}/${ApiUrlRequestBuilder.BASE_API_PATH}/${Utils.getApiPath()}${endpoint}`;
  }

  public getRootEndPoint(endpoint: string): string {
    endpoint = endpoint ? `/${endpoint}` : "";
    return `${Utils.getEndpointContextPath()}/${ApiUrlRequestBuilder.BASE_API_PATH}${endpoint}`;
  }

  public getOuthTokenPath(): string {
    return `${Utils.getEndpointContextPath()}/oauth/token`;
  }

  public getHeaderForBasicAuthorization(): any {
    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${window.btoa(Utils.getAuthorizationData())}`,
      },
    };
  }

  public getHeaderForRevokeAuthorization(): any {
    const barearAccessToken: string = Utils.localStorageGetItem(Utils.BEARER_ACCESS_TOKEN_KEY);

    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${window.btoa(Utils.getAuthorizationData())}`,
        "Revoke-Authorization": `Bearer ${barearAccessToken}`,
      },
    };
  }

  public getJsonHeadersOption(): any {
    return { headers: { "Content-Type": "application/json" } };
  }

  public getIpnifoHeadersOption(): any {
    return { headers: { Authorization: "Bearer ipinfo.io" } };
  }
}

export { ApiUrlRequestBuilder };
