export default class DfProperty {
  private _type: string;
  private _code: string;
  private _label: string;
  private _required = false;
  private _listOptions: Array<any> = [];
  private _values: Array<any>;
  private _maxOccurrence = 1;
  private _minOccurrence = 1;
  private _readOnly = false;

  constructor(type: string, code: string, label: string, values: Array<any>) {
    this._type = type;
    this._code = code;
    this._label = label;
    this._values = values;
  }
  get type(): string {
    return this._type;
  }

  set type(type: string) {
    this._type = type;
  }

  get code(): string {
    return this._code;
  }

  set code(code: string) {
    this._code = code;
  }

  get label(): string {
    return this._label;
  }

  set label(label: string) {
    this._label = label;
  }

  get required(): boolean {
    return this._required;
  }

  set required(required: boolean) {
    this._required = required;
  }

  get values(): Array<any> {
    return this._values;
  }

  set values(values: Array<any>) {
    this._values = values;
  }

  get minOccurrence(): number {
    return this._minOccurrence;
  }

  set minOccurrence(minOccurrence: number) {
    this._minOccurrence = minOccurrence;
  }

  get maxOccurrence(): number {
    return this._maxOccurrence;
  }

  set maxOccurrence(maxOccurrence: number) {
    this._maxOccurrence = maxOccurrence;
  }

  get readOnly(): boolean {
    return this._readOnly;
  }

  set readOnly(readOnly: boolean) {
    this._readOnly = readOnly;
  }

  get listOptions(): Array<any> {
    return this._listOptions;
  }

  set listOptions(listOptions: Array<any>) {
    this._listOptions = listOptions;
  }

  get isString(): boolean {
    return this.type === "STRING";
  }

  get isNumber(): boolean {
    return this.type === "NUMBER";
  }

  get isFile(): boolean {
    return this.type === "FILE";
  }

  get isDate(): boolean {
    return this.type === "DATE";
  }

  get isBoolean(): boolean {
    return this.type === "BOOLEAN";
  }

  get isText(): boolean {
    return this.type === "TEXT";
  }

  get isList(): boolean {
    return this.type === "LIST";
  }

  get typeValue(): string {
    switch (this.type) {
      case "STRING":
        return "string";
      case "NUMBER":
        return "number";
      case "FILE":
        return "fileName";
      case "DATE":
        return "date";
      case "BOOLEAN":
        return "boolean";
      case "TEXT":
        return "text";
      case "LIST":
        return "list";
    }
  }
}
