import DfCode from "@/vue/domain/brand/df-code";
import DfProperty from "@/vue/domain/content/df-property";

export default class DfProduct {
  private _uniqueId: string;
  private _alias: string;
  private _description: string;
  private _code: DfCode;
  private _properties: Array<DfProperty>;
  private _groups: Array<string>;

  constructor(
    uniqueId: string,
    alias: string,
    description: string,
    code: DfCode,
    properties: Array<DfProperty>,
    groups: Array<string>
  ) {
    this._uniqueId = uniqueId;
    this._alias = alias;
    this._description = description;
    this._code = code;
    this._properties = properties;
    this._groups = groups;
  }

  get uniqueId(): string {
    return this._uniqueId;
  }

  set uniqueId(uniqueId: string) {
    this._uniqueId = uniqueId;
  }

  get alias(): string {
    return this._alias;
  }

  set alias(alias: string) {
    this._alias = alias;
  }

  get description(): string {
    return this._description;
  }

  set description(description: string) {
    this._description = description;
  }

  get code(): DfCode {
    return this._code;
  }

  set code(code: DfCode) {
    this._code = code;
  }

  get properties(): Array<DfProperty> {
    return this._properties;
  }

  set properties(properties: Array<DfProperty>) {
    this._properties = properties;
  }

  get groups(): Array<string> {
    return this._groups;
  }

  set groups(groups: Array<string>) {
    this._groups = groups;
  }
}
