export default class DfCode {
  private _type: string;
  private _value: string;

  constructor(identifier: string, alias: string) {
    this._type = identifier;
    this._value = alias;
  }

  get type(): string {
    return this._type;
  }

  set type(type: string) {
    this._type = type;
  }

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
  }
}
