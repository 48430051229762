export default class DfDateRange {
  private _name: string;
  private _note: string;
  private _startDate: string;
  private _startTime: string;
  private _endDate: string;
  private _endTime: string;
  private _additionalStartTime: string;
  private _additionalEndTime: string;

  constructor(name: string, note: string, startDate: string, startTime: string, additionalStartTime: string, endDate: string, endTime: string, additionalEndTime: string) {
    this._name = name;
    this._note = note;
    this._startDate = startDate;
    this._startTime = startTime;
    this._additionalStartTime = additionalStartTime;
    this._endDate = endDate;
    this._endTime = endTime;
    this._additionalEndTime = additionalEndTime;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get note(): string {
    return this._note;
  }

  set note(note: string) {
    this._note = note;
  }

  get startDate(): string {
    return this._startDate;
  }

  set startDate(startDate: string) {
    this._startDate = startDate;
  }

  get startTime(): string {
    return this._startTime;
  }

  set startTime(startTime: string) {
    this._startTime = startTime;
  }

  get endDate(): string {
    return this._endDate;
  }

  set endDate(endDate: string) {
    this._endDate = endDate;
  }

  get endTime(): string {
    return this._endTime;
  }

  set endTime(endTime: string) {
    this._endTime = endTime;
  }

  get additionalStartTime(): string {
    return this._additionalStartTime;
  }

  set additionalStartTime(additionalStartTime: string) {
    this._additionalStartTime = additionalStartTime;
  }

  get additionalEndTime(): string {
    return this._additionalEndTime;
  }

  set additionalEndTime(additionalEndTime: string) {
    this._additionalEndTime = additionalEndTime;
  }
}
