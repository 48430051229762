import DfDayOfWeek from "@/vue/domain/promotion/df-days-of-week";
import DfProperty from "@/vue/domain/content/df-property";

export default class DfPromotion {
  private _identifier: string;
  private _code: string;
  private _alias: string;
  private _description: string;
  private _priority: number;
  private _startDate: string;
  private _endDate: string;
  private _startTime: string;
  private _endTime: string;
  private _daysOfWeek: Array<DfDayOfWeek>;
  private _properties: Array<DfProperty>;
  private _hidden: boolean;

  constructor(
    identifier: string,
    code: string,
    alias: string,
    description: string,
    priority: number,
    startDate: string,
    endDate: string,
    startTime: string,
    endTime: string,
    daysOfWeek: Array<DfDayOfWeek>,
    properties: Array<DfProperty>,
    hidden: boolean
  ) {
    this._identifier = identifier;
    this._code = code;
    this._alias = alias;
    this._description = description;
    this._priority = priority;
    this._startDate = startDate;
    this._endDate = endDate;
    this._startTime = startTime;
    this._endTime = endTime;
    this._daysOfWeek = daysOfWeek;
    this._properties = properties;
    this._hidden = hidden;
  }

  get identifier(): string {
    return this._identifier;
  }

  set identifier(identifier: string) {
    this._identifier = identifier;
  }

  get code(): string {
    return this._code;
  }

  set code(code: string) {
    this._code = code;
  }

  get alias(): string {
    return this._alias;
  }

  set alias(alias: string) {
    this._alias = alias;
  }

  get description(): string {
    return this._description;
  }

  set description(description: string) {
    this._description = description;
  }

  get priority(): number {
    return this._priority;
  }

  set priority(priority: number) {
    this._priority = priority;
  }

  get startDate(): string {
    return this._startDate;
  }

  set startDate(startDate: string) {
    this._startDate = startDate;
  }

  get endDate(): string {
    return this._endDate;
  }

  set endDate(endDate: string) {
    this._endDate = endDate;
  }

  get daysOfWeek(): Array<DfDayOfWeek> {
    return this._daysOfWeek;
  }

  set daysOfWeek(daysOfWeek: Array<DfDayOfWeek>) {
    this._daysOfWeek = daysOfWeek;
  }

  get properties(): Array<DfProperty> {
    return this._properties;
  }

  set properties(properties: Array<DfProperty>) {
    this._properties = properties;
  }

  get hidden(): boolean {
    return this._hidden;
  }

  set hidden(hidden: boolean) {
    this._hidden = hidden;
  }
}
