const langIt_euronics_butali = {
  "df-store-card.store-selection": "Scegli il negozio",

  "df-card-shopping-list.label": "Lista",
  "df-card-shopping-list.title": "Lista {0}",
  "df-card-shopping-list.empty-list": "La lista è vuota",
  "df-card-shopping-list.delete.body": "Stai per eliminare i prodotti dalla lista. Continuare?",

  "df-product-datails-data.add-shoppin-list": "Aggiungi alla lista",
  "df-product-datails-data.added-shoppin-list": "Aggiunto alla lista",

  "df-router-title-page-shopping-list": "Lista",

  "df-loan-tranche-count": "IN {0} RATE DA*",
};

export default langIt_euronics_butali;
