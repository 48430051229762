const langIt_maxidi_dpiu = {
  "df-router-title-page-club-card": "DPiù Extra",
  "df-section-links.club-card": "DPiù Extra",
  "df-page-club-card.insert-club-card-number": "Inserisci la tua Carta DPiù Extra",
  "df-form-registration.form.terms-club-card-acceptance.link": "il Regolamento DPiù Extra",
  "df-form-registration.swal.card-club.title": "Regolamento DPiù Extra",
  "dfUserRegistrationForm-clubCardConsent-Mandatory": "Per registrarsi è necessario accettare il Regolamento DPiù Extra",
};

export default langIt_maxidi_dpiu;
