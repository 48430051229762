import { Enumify } from "enumify";

class DfNewsletterSubscribeStatus extends Enumify {
  static MISSING_FIELDS = new DfNewsletterSubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-subscribe-status.MISSING_FIELDS";
    },
  });
  static NO_API_KEY = new DfNewsletterSubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-subscribe-status.NO_API_KEY";
    },
  });
  static INVALID_API_KEY = new DfNewsletterSubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-subscribe-status.INVALID_API_KEY";
    },
  });
  static INVALID_EMAIL_ADDRESS = new DfNewsletterSubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-subscribe-status.INVALID_EMAIL_ADDRESS";
    },
  });
  static ALREADY_SUBSCRIBED = new DfNewsletterSubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-subscribe-status.ALREADY_SUBSCRIBED";
    },
  });
  static BOUNCED_EMAIL_ADDRESS = new DfNewsletterSubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-subscribe-status.BOUNCED_EMAIL_ADDRESS";
    },
  });
  static SUPPRESSED_EMAIL_ADDRESS = new DfNewsletterSubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-subscribe-status.SUPPRESSED_EMAIL_ADDRESS";
    },
  });
  static INVALID_LIST_ID = new DfNewsletterSubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-subscribe-status.INVALID_LIST_ID";
    },
  });
  static TRUE = new DfNewsletterSubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-subscribe-status.TRUE";
    },
  });
  static FALSE = new DfNewsletterSubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-subscribe-status.FALSE";
    },
  });
  static _ = DfNewsletterSubscribeStatus.closeEnum();

  label!: string;
  constructor(props: { [k: string]: any }) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }

  static getEnum(enumKey: string): DfNewsletterSubscribeStatus {
    switch (enumKey) {
      case DfNewsletterSubscribeStatus.MISSING_FIELDS.enumKey:
        return DfNewsletterSubscribeStatus.MISSING_FIELDS;
      case DfNewsletterSubscribeStatus.NO_API_KEY.enumKey:
        return DfNewsletterSubscribeStatus.NO_API_KEY;
      case DfNewsletterSubscribeStatus.INVALID_API_KEY.enumKey:
        return DfNewsletterSubscribeStatus.INVALID_API_KEY;
      case DfNewsletterSubscribeStatus.INVALID_EMAIL_ADDRESS.enumKey:
        return DfNewsletterSubscribeStatus.INVALID_EMAIL_ADDRESS;
      case DfNewsletterSubscribeStatus.ALREADY_SUBSCRIBED.enumKey:
        return DfNewsletterSubscribeStatus.ALREADY_SUBSCRIBED;
      case DfNewsletterSubscribeStatus.BOUNCED_EMAIL_ADDRESS.enumKey:
        return DfNewsletterSubscribeStatus.BOUNCED_EMAIL_ADDRESS;
      case DfNewsletterSubscribeStatus.SUPPRESSED_EMAIL_ADDRESS.enumKey:
        return DfNewsletterSubscribeStatus.SUPPRESSED_EMAIL_ADDRESS;
      case DfNewsletterSubscribeStatus.INVALID_LIST_ID.enumKey:
        return DfNewsletterSubscribeStatus.INVALID_LIST_ID;
      case DfNewsletterSubscribeStatus.TRUE.enumKey:
        return DfNewsletterSubscribeStatus.TRUE;
      default:
        return DfNewsletterSubscribeStatus.FALSE;
    }
  }
}
export default DfNewsletterSubscribeStatus;
