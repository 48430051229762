import VueRouter from "vue-router";
import Utils from "@/vue/infrastructure/utils/helpers";

function DcRouter(modeType: any): VueRouter {
  return new VueRouter({
    mode: modeType,
    base: Utils.getBasePath(),
    routes: [
      {
        name: "form-mail",
        path: "/punti-vendita/:storeAlias/forms/:formMailAlias",
        component: () => import(/* webpackChunkName: "df-page-form-mail" */ "@/vue/components/df-page-form-mail/df-page-form-mail.vue"),
      },
      {
        name: "home-store",
        path: "/punti-vendita/:storeAlias",
        meta: {
          title: "df-router-title-page-home",
        },
        component: () => import(/* webpackChunkName: "df-page-home" */ "@/vue/components/df-page-home/df-page-home.vue"),
      },
      {
        name: "promotions",
        path: "/punti-vendita/:storeAlias/promozioni",
        meta: {
          title: "df-router-title-page-promotions",
        },
        component: () => import(/* webpackChunkName: "df-page-promotions" */ "@/vue/components/df-page-promotions/df-page-promotions.vue"),
      },
      {
        name: "new",
        path: "/punti-vendita/:storeAlias/news/:newsAlias",
        component: () => import(/* webpackChunkName: "df-page-new" */ "@/vue/components/df-page-new/df-page-new.vue"),
      },
      {
        name: "news",
        path: "/punti-vendita/:storeAlias/news",
        meta: {
          title: "df-router-title-page-news",
        },
        component: () => import(/* webpackChunkName: "df-page-news" */ "@/vue/components/df-page-news/df-page-news.vue"),
      },
      {
        name: "newsletter",
        path: "/punti-vendita/:storeAlias/newsletter",
        meta: {
          title: "df-router-title-page-newsletter",
        },
        component: () => import(/* webpackChunkName: "df-page-newsletter" */ "@/vue/components/df-page-newsletter/df-page-newsletter.vue"),
      },
      {
        name: "notifications",
        path: "/punti-vendita/:storeAlias/notifiche",
        meta: {
          title: "df-router-title-page-notifications",
        },
        component: () => import(/* webpackChunkName: "df-page-notifications" */ "@/vue/components/df-page-notifications/df-page-notifications.vue"),
      },
      {
        name: "recipes",
        path: "/punti-vendita/:storeAlias/ricette",
        meta: {
          title: "df-router-title-page-recipes",
        },
        component: () => import(/* webpackChunkName: "df-page-recipes" */ "@/vue/components/df-page-recipes/df-page-recipes.vue"),
      },
      {
        name: "shopping-list",
        path: "/punti-vendita/:storeAlias/lista-della-spesa",
        meta: {
          title: "df-router-title-page-shopping-list",
        },
        component: () => import(/* webpackChunkName: "df-page-shopping-list" */ "@/vue/components/df-page-shopping-list/df-page-shopping-list.vue"),
      },
      {
        name: "promotion",
        path: "/punti-vendita/:storeAlias/promozioni/:promotionAlias",
        component: () => import(/* webpackChunkName: "df-page-promotion" */ "@/vue/components/df-page-promotion/df-page-promotion.vue"),
      },
      {
        name: "promotion_recipes",
        path: "/punti-vendita/:storeAlias/promozioni/:promotionAlias/ricette",
        meta: {
          title: "df-router-title-page-recipes",
        },
        component: () => import(/* webpackChunkName: "df-page-recipes" */ "@/vue/components/df-page-recipes/df-page-recipes.vue"),
      },
      {
        name: "promotion_recipe",
        path: "/punti-vendita/:storeAlias/promozioni/:promotionAlias/ricette/:recipeAlias",
        component: () => import(/* webpackChunkName: "df-page-recipe" */ "@/vue/components/df-page-recipe/df-page-recipe.vue"),
      },
      {
        name: "promotion-special",
        path: "/punti-vendita/:storeAlias/promozioni/:promotionAlias/speciali/:specialAlias",
        component: () => import(/* webpackChunkName: "df-page-special" */ "@/vue/components/df-page-special/df-page-special.vue"),
      },
      {
        name: "store",
        path: "/punti-vendita/:storeAlias/informazioni",
        meta: {
          title: "df-router-title-page-store",
        },
        component: () => import(/* webpackChunkName: "df-page-store" */ "@/vue/components/df-page-store/df-page-store.vue"),
      },
      {
        name: "store-change",
        path: "/punti-vendita/:storeAlias/cambio-punto-vendita",
        meta: {
          title: "df-router-title-page-store-change",
        },
        component: () => import(/* webpackChunkName: "df-page-store-change" */ "@/vue/components/df-page-store-change/df-page-store-change.vue"),
      },
      {
        name: "account-activation",
        path: "/punti-vendita/:storeAlias/attivazione-account",
        meta: {
          title: "df-router-title-page-account-activation",
        },
        component: () => import(/* webpackChunkName: "df-page-home" */ "@/vue/components/df-page-account-activation/df-page-account-activation.vue"),
      },
      {
        name: "email-change",
        path: "/punti-vendita/:storeAlias/cambio-email",
        meta: {
          title: "df-router-title-page-cambio-email",
        },
        component: () => import(/* webpackChunkName: "df-page-email-change" */ "@/vue/components/df-page-email-change/df-page-email-change.vue"),
      },
      {
        name: "product",
        path: "/punti-vendita/:storeAlias/promozioni/:promotionAlias/prodotti/:productAlias/",
        component:() => Utils.externalComponent2("df-page-product"),
      },
      {
        name: "product-by-code",
        path: "/product",
        component:() => Utils.externalComponent2("df-page-product-by-code"),
      },
      {
        name: "club-card",
        path: "/punti-vendita/:storeAlias/carta-club",
        meta: {
          title: "df-router-title-page-club-card",
        },
        component: () => import(/* webpackChunkName: "df-club-card" */ "@/vue/components/df-page-club-card/df-page-club-card.vue"),
      },
      {
        name: "recipe",
        path: "/punti-vendita/:storeAlias/ricette/:recipeAlias",
        component: () => import(/* webpackChunkName: "df-page-recipe" */ "@/vue/components/df-page-recipe/df-page-recipe.vue"),
      },
      {
        name: "store-selection",
        path: "/punti-vendita",
        meta: {
          title: "df-router-title-page-store-selection",
        },
        component: () => import(/* webpackChunkName: "df-page-store-selection" */ "@/vue/components/df-page-store-selection/df-page-store-selection.vue"),
      },
      {
        name: "page",
        path: "/contenuti/:pageAlias",
        component: () => import(/* webpackChunkName: "df-page-page" */ "@/vue/components/df-page-page/df-page-page.vue"),
      },
      {
        name: "user-registration",
        path: "/punti-vendita/:storeAlias/registrazione",
        meta: {
          title: "df-router-title-page-user-registration",
        },
        component: () => Utils.externalComponent2("df-page-user-registration"),
      },
      {
        name: "user-profile-edit",
        path: "/account/modifica-profilo",
        meta: {
          title: "df-router-title-page-user-profile-edit",
        },
        component: () => Utils.externalComponent2("df-page-user-profile-edit"),
      },
      {
        name: "account-expenses",
        path: "/account/spese",
        meta: {
          title: "df-router-title-page-account-expenses",
        },
        component: () => Utils.externalComponent2("df-page-account-expenses"),
      },
      {
        name: "account-cashback",
        path: "/account/cashback",
        meta: {
          title: "df-router-title-page-account-cashback",
        },
        component: () => Utils.externalComponent2("df-page-account-cashback"),
      },
      {
        name: "account-card",
        path: "/account/tessera",
        meta: {
          title: "df-router-title-page-account-card",
        },
        component: () => Utils.externalComponent2("df-page-account-card"),
      },
      {
        name: "password-forgotten",
        path: "/password-dimenticata/:resetPasswordToken",
        meta: {
          title: "df-router-title-page-password-forgotten",
        },
        component: () => Utils.externalComponent2("df-page-password-forgotten"),
      },
      {
        name: "errors",
        path: "/errors/:errorCode",
        component: () => Utils.externalComponent2("df-page-error"),
      },
      {
        name: "email-change-no-store",
        path: "/cambio-email",
        meta: {
          title: "df-router-title-page-cambio-email",
        },
        component: () => import(/* webpackChunkName: "df-page-email-change" */ "@/vue/components/df-page-email-change/df-page-email-change.vue"),
      },
      {
        name: "account-activation-no-store",
        path: "/attivazione-account",
        meta: {
          title: "df-router-title-page-account-activation",
        },
        component: () => import(/* webpackChunkName: "df-page-home" */ "@/vue/components/df-page-account-activation/df-page-account-activation.vue"),
      },
      // DA ELIMINARE IN  FUTURO  - 20/12/2021 [tra  un  anno]
      { name: "promotions-no-store", meta: { redirectTo: "promotions" }, path: "/promozioni" },
      { name: "news-no-store", meta: { redirectTo: "news" }, path: "/news" },
      { name: "recipes-no-store", meta: { redirectTo: "recipes" }, path: "/ricette" },
      { name: "new-no-store", meta: { redirectTo: "new" }, path: "/news/:newsAlias" },
      { name: "notifications-no-store", meta: { redirectTo: "notifications" }, path: "/notifiche" },
      { name: "shopping-list-no-store", meta: { redirectTo: "shopping-list" }, path: "/lista-della-spesa" },
      { name: "promotion-no-store", meta: { redirectTo: "promotion" }, path: "/promozioni/:promotionAlias" },
      { name: "promotion-special-no-store", meta: { redirectTo: "promotion-special" }, path: "/promozioni/:promotionAlias/speciali/:specialAlias" },
      { name: "store-no-store", meta: { redirectTo: "store" }, path: "/informazioni" },
      { name: "store-change-no-store", meta: { redirectTo: "store-change" }, path: "/cambio-punto-vendita" },
      { name: "product-no-store", meta: { redirectTo: "product" }, path: "/promozioni/:promotionAlias/prodotti/:productAlias" },
      { name: "club-card-no-store", meta: { redirectTo: "club-card" }, path: "/carta-club" },
      { name: "recipe-no-store", meta: { redirectTo: "recipe" }, path: "/ricette/:recipeAlias" },
      { name: "newsletter-no-store", meta: { redirectTo: "newsletter" }, path: "/newsletter" },
      { name: "form-mail-no-store", meta: { redirectTo: "form-mail" }, path: "/forms/:formMailAlias" },
      { name: "card-no-store", meta: { redirectTo: "club-card" }, path: "/card" },
      { name: "promotion-no-store-2", meta: { redirectTo: "promotion" }, path: "/promotions/:promotionAlias" },
      { name: "user-registration-no-store", meta: { redirectTo: "user-registration" }, path: "/user-registration" },
      { name: "user-profile-edit-no-store", meta: { redirectTo: "user-profile-edit" }, path: "/user-profile-edit" },
    ],
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return {
          selector: to.hash,
          offset: { x: 0, y: to.params.offsetY ? Number.parseInt(to.params.offsetY) : 0 },
        };
      }
      return { x: 0, y: 0 };
    },
  });
}
export default DcRouter;
