export default class DfCoordinates {
  private _latitude: number;
  private _longitude: number;

  constructor(latitude: number, longitude: number) {
    this._latitude = latitude;
    this._longitude = longitude;
  }

  get latitude(): number {
    return this._latitude;
  }

  set latitude(latitude: number) {
    this._latitude = latitude;
  }

  get longitude(): number {
    return this._longitude;
  }

  set longitude(longitude: number) {
    this._longitude = longitude;
  }
}
