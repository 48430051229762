const langIt_superemme_hardis = {
  "df-store-card.store-selection": "Scegli il discount",

  "df-router-title-page-account-card": "La tua Fidelity",

  "df-private-area.tab.userCard": "La tua Fidelity",

  "df-page-account-card.page.title": "La tua Fidelity",
};

export default langIt_superemme_hardis;
