import { Enumify } from "enumify";

class DfDayOfWeek extends Enumify {
  static MONDAY = new DfDayOfWeek({
    get label(): string {
      return "enum-df-day-of-week.monday";
    },
  });
  static TUESDAY = new DfDayOfWeek({
    get label(): string {
      return "enum-df-day-of-week.tuesday";
    },
  });
  static WEDNESDAY = new DfDayOfWeek({
    get label(): string {
      return "enum-df-day-of-week.wednesday";
    },
  });
  static THURSDAY = new DfDayOfWeek({
    get label(): string {
      return "enum-df-day-of-week.thursday";
    },
  });
  static FRIDAY = new DfDayOfWeek({
    get label(): string {
      return "enum-df-day-of-week.friday";
    },
  });
  static SATURDAY = new DfDayOfWeek({
    get label(): string {
      return "enum-df-day-of-week.saturday";
    },
  });
  static SUNDAY = new DfDayOfWeek({
    get label(): string {
      return "enum-df-day-of-week.sunday";
    },
  });
  static _ = DfDayOfWeek.closeEnum();

  label!: string;
  constructor(props: { [k: string]: any }) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }

  static getEnum(enumKey: string): DfDayOfWeek {
    switch (enumKey) {
      case DfDayOfWeek.MONDAY.enumKey:
        return DfDayOfWeek.MONDAY;
      case DfDayOfWeek.TUESDAY.enumKey:
        return DfDayOfWeek.TUESDAY;
      case DfDayOfWeek.WEDNESDAY.enumKey:
        return DfDayOfWeek.WEDNESDAY;
      case DfDayOfWeek.THURSDAY.enumKey:
        return DfDayOfWeek.THURSDAY;
      case DfDayOfWeek.FRIDAY.enumKey:
        return DfDayOfWeek.FRIDAY;
      case DfDayOfWeek.SATURDAY.enumKey:
        return DfDayOfWeek.SATURDAY;
      case DfDayOfWeek.SUNDAY.enumKey:
        return DfDayOfWeek.SUNDAY;
      default:
        return null;
    }
  }
}
export default DfDayOfWeek;
