import { Enumify } from "enumify";

class DfSchedulingStatus extends Enumify {
  static VALID = new DfSchedulingStatus({
    get label(): string {
      return "enum-df-scheduling-status.valid";
    },
  });
  static FORTHCOMING = new DfSchedulingStatus({
    get label(): string {
      return "enum-df-scheduling-status.forthcoming";
    },
  });
  static EXPIRED = new DfSchedulingStatus({
    get label(): string {
      return "enum-df-scheduling-status.expired";
    },
  });
  label!: string;
  constructor(props: { [k: string]: any }) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }

  static getEnum(enumKey: string): DfSchedulingStatus {
    switch (enumKey) {
      case DfSchedulingStatus.VALID.enumKey:
        return DfSchedulingStatus.VALID;
      case DfSchedulingStatus.FORTHCOMING.enumKey:
        return DfSchedulingStatus.FORTHCOMING;
      case DfSchedulingStatus.EXPIRED.enumKey:
        return DfSchedulingStatus.EXPIRED;
      default:
        return null;
    }
  }
}
export default DfSchedulingStatus;
