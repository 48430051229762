import DfDayOfWeek from "@/vue/domain/promotion/df-days-of-week";

export default class DfDayHoursActivityRange {
  private _dayOfWeek: DfDayOfWeek;
  private _startTime: string;
  private _endTime: string;
  private _additionalStartTime: string;
  private _additionalEndTime: string;

  constructor(dayOfWeek: DfDayOfWeek, startTime: string, endTime: string, additionalStartTime: string, additionalEndTime: string) {
    this._dayOfWeek = dayOfWeek;
    this._startTime = startTime;
    this._endTime = endTime;
    this._additionalStartTime = additionalStartTime;
    this._additionalEndTime = additionalEndTime;
  }

  get dayOfWeek(): DfDayOfWeek {
    return this._dayOfWeek;
  }

  set dayOfWeek(dayOfWeek: DfDayOfWeek) {
    this._dayOfWeek = dayOfWeek;
  }

  get startTime(): string {
    return this._startTime;
  }

  set startTime(startTime: string) {
    this._startTime = startTime;
  }

  get endTime(): string {
    return this._endTime;
  }

  set endTime(endTime: string) {
    this._endTime = endTime;
  }

  get additionalStartTime(): string {
    return this._additionalStartTime;
  }

  set additionalStartTime(additionalStartTime: string) {
    this._additionalStartTime = additionalStartTime;
  }

  get additionalEndTime(): string {
    return this._additionalEndTime;
  }

  set additionalEndTime(additionalEndTime: string) {
    this._additionalEndTime = additionalEndTime;
  }
}
