export default class DfGroup {
  private _identifier: string;
  private _code: string;
  private _alias: string;
  private _name: string;
  private _productsUniqueId: Array<string>;

  constructor(
    identifier: string,
    code: string,
    alias: string,
    name: string,
    productsUniqueId: Array<string>
  ) {
    this._identifier = identifier;
    this._code = code;
    this._alias = alias;
    this._name = name;
    this._productsUniqueId = productsUniqueId;
  }

  get identifier(): string {
    return this._identifier;
  }

  set identifier(identifier: string) {
    this._identifier = identifier;
  }

  get code(): string {
    return this._code;
  }

  set code(code: string) {
    this._code = code;
  }

  get alias(): string {
    return this._alias;
  }

  set alias(alias: string) {
    this._alias = alias;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get productsUniqueId(): Array<string> {
    return this._productsUniqueId;
  }

  set productsUniqueId(productsUniqueId: Array<string>) {
    this._productsUniqueId = productsUniqueId;
  }
}
