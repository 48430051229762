import { Enumify } from "enumify";

class DfNewsletterUnsubscribeStatus extends Enumify {
  static MISSING_FIELDS = new DfNewsletterUnsubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-unsubscribe-status.MISSING_FIELDS";
    },
  });
  static INVALID_EMAIL_ADDRESS = new DfNewsletterUnsubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-unsubscribe-status.INVALID_EMAIL_ADDRESS";
    },
  });
  static EMAIL_ADDRESS_NOT_FOUND = new DfNewsletterUnsubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-unsubscribe-status.EMAIL_ADDRESS_NOT_FOUND";
    },
  });
  static TRUE = new DfNewsletterUnsubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-unsubscribe-status.TRUE";
    },
  });
  static FALSE = new DfNewsletterUnsubscribeStatus({
    get label(): string {
      return "enum-df-newsletter-unsubscribe-status.FALSE";
    },
  });
  static _ = DfNewsletterUnsubscribeStatus.closeEnum();

  label!: string;
  constructor(props: { [k: string]: any }) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }

  static getEnum(enumKey: string): DfNewsletterUnsubscribeStatus {
    switch (enumKey) {
      case DfNewsletterUnsubscribeStatus.MISSING_FIELDS.enumKey:
        return DfNewsletterUnsubscribeStatus.MISSING_FIELDS;
      case DfNewsletterUnsubscribeStatus.INVALID_EMAIL_ADDRESS.enumKey:
        return DfNewsletterUnsubscribeStatus.INVALID_EMAIL_ADDRESS;
      case DfNewsletterUnsubscribeStatus.EMAIL_ADDRESS_NOT_FOUND.enumKey:
        return DfNewsletterUnsubscribeStatus.EMAIL_ADDRESS_NOT_FOUND;
      case DfNewsletterUnsubscribeStatus.TRUE.enumKey:
        return DfNewsletterUnsubscribeStatus.TRUE;
      default:
        return DfNewsletterUnsubscribeStatus.FALSE;
    }
  }
}
export default DfNewsletterUnsubscribeStatus;
