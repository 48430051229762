import it from "./messages.it_IT";
import en from "./messages.en_EN";
import it_euronics_butali from "./messages.it_IT_euronics_butali";
import it_euronics_nova from "./messages.it_IT_euronics_nova";
import it_superemme_hardis from "./messages.it_IT_superemme_hardis";
import it_migross_cash_and_carry from "./messages.it_IT_migross_cash_and_carry";
import it_migross from "./messages.it_IT_migross_migross";
import it_arca_famila from "./messages.it_IT_arca_famila";
import it_maxidi_dpiu from "./messages.it_IT_maxidi_dpiu";

const messageList: any = { it, en, it_euronics_butali, it_euronics_nova, it_superemme_hardis, it_migross_cash_and_carry, it_migross, it_arca_famila, it_maxidi_dpiu };
export default messageList;
