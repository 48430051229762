import { MutationTree } from "vuex";
import StoreStateInterface from "./store-state-interface";

const storeMutations: MutationTree<StoreStateInterface> = {
  setStoreMutation: (state, stores) => {
    state.stores = stores;
  },

  setBrandMutation: (state, brand) => {
    state.brand = brand;
  },

  setPromotionsMutation: (state, promotions) => {
    state.promotions = promotions;
  },

  setCurrentStoreMutation: (state, currentStore) => {
    state.currentStore = currentStore;
  },

  setCurrentPromotionMutation: (state, currentPromotion) => {
    state.currentPromotion = currentPromotion;
  },

  setProductsMutation: (state, products) => {
    state.products = products;
  },

  setProductsPagingMutation: (state, productsPaging) => {
    state.productsPaging = productsPaging;
  },

  setStoresPagingMutation: (state, storesPaging) => {
    state.storesPaging = storesPaging;
  },

  setShoppingListMutation: (state, shoppingList) => {
    state.shoppingList = shoppingList;
  },

  setActiveTagsMutation: (state, activeTags) => {
    state.activeTags = activeTags;
  },

  setcustomFiltersQueryMutation: (state, customFiltersQuery) => {
    state.customFiltersQuery = customFiltersQuery;
  },

  setActiveCategoryMutation: (state, activeCategory) => {
    state.activeCategory = activeCategory;
  },

  setPromotionContentsMutation: (state, promotionContents) => {
    state.promotionContents = promotionContents;
  },

  setStoreContentsMutation: (state, storeContents) => {
    state.storeContents = storeContents;
  },

  setPromotionGroupsMutation: (state, promotionGroups) => {
    state.promotionGroups = promotionGroups;
  },

  setCachedProductsMutation: (state, cachedProducts) => {
    state.cachedProducts = cachedProducts;
  },

  setStoreOpeningsMutation: (state, storeOpenings) => {
    state.storeOpenings = storeOpenings;
  },

  setStoreClosuresMutation: (state, storeClosures) => {
    state.storeClosures = storeClosures;
  },

  setBrandContentsMutation: (state, brandContents) => {
    state.brandContents = brandContents;
  },

  setProductsQueryValueMutation: (state, productsQueryValue) => {
    state.productsQueryValue = productsQueryValue;
  },

  setStoredPushesListMutation: (state, storedPushesList) => {
    state.storedPushesList = storedPushesList;
  },

  setScrollbarHiddenMutation: (state, scrollbarHidden) => {
    state.scrollbarHidden = scrollbarHidden;
  },

  setInitialRouteMutation: (state, initialRoute) => {
    state.initialRoute = initialRoute;
  },

  setHistoryMutation: (state, history) => {
    state.history = history;
  },

  setHistoryIndexMutation: (state, historyIndex) => {
    state.historyIndex = historyIndex;
  },

  setClientNotificationMutation: (state, clientNotification) => {
    state.clientNotification = clientNotification;
  },

  setGeolocationEnabledMutation: (state, geolocationEnabled) => {
    state.geolocationEnabled = geolocationEnabled;
  },

  setPromotionsLoadedMutation: (state, promotionsLoaded) => {
    state.promotionsLoaded = promotionsLoaded;
  },

  setPromotionsPopupsWhatchedMutation: (state, promotionsPopupsWhatched) => {
    state.promotionsPopupsWhatched = promotionsPopupsWhatched;
  },

  setUserMutation: (state, user) => {
    state.user = user;
  },

  setLoginMailMutation: (state, loginMail) => {
    state.loginMail = loginMail;
  },

  setShowLoginMutation: (state, showLogin) => {
    state.showLogin = showLogin;
  },
};

export default storeMutations;
