import DfProvince from "@/vue/domain/store/df-province";
import DfDayHoursActivityRange from "@/vue/domain/store/df-day-hours-activity-range";
import DfProperty from "@/vue/domain/content/df-property";
import DfCoordinates from "@/vue/domain/store/df-coordinates";

export default class DfStore {
  private _identifier: string;
  private _alias: string;
  private _code: string;
  private _name: string;
  private _catchmentArea: string = null;
  private _country: string = null;
  private _province: DfProvince = null;
  private _city: string = null;
  private _postalCode: string = null;
  private _address: string = null;
  private _phone: string = null;
  private _fax: string = null;
  private _email: string = null;
  private _notes: string = null;
  private _openingTimes: Array<DfDayHoursActivityRange> = [];
  private _pickingTimes: Array<DfDayHoursActivityRange> = [];
  private _availableForCollect: boolean;
  private _availableForDelivery: boolean;
  private _gpsCoordinates: DfCoordinates = null;
  private _properties: Array<DfProperty>;
  private _distance: number = null;

  constructor(identifier: string, alias: string, name: string, code: string) {
    this._identifier = identifier;
    this._alias = alias;
    this._name = name;
    this._code = code;
  }

  get identifier(): string {
    return this._identifier;
  }

  set identifier(identifier: string) {
    this._identifier = identifier;
  }

  get alias(): string {
    return this._alias;
  }

  set alias(alias: string) {
    this._alias = alias;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get code(): string {
    return this._code;
  }

  set code(code: string) {
    this._code = code;
  }

  get country(): string {
    return this._country;
  }

  set country(country: string) {
    this._country = country;
  }

  get catchmentArea(): string {
    return this._catchmentArea;
  }

  set catchmentArea(catchmentArea: string) {
    this._catchmentArea = catchmentArea;
  }

  get province(): DfProvince {
    return this._province;
  }

  set province(province: DfProvince) {
    this._province = province;
  }

  get address(): string {
    return this._address;
  }

  set address(address: string) {
    this._address = address;
  }

  get phone(): string {
    return this._phone;
  }

  set phone(phone: string) {
    this._phone = phone;
  }

  get fax(): string {
    return this._fax;
  }

  set fax(fax: string) {
    this._fax = fax;
  }

  get city(): string {
    return this._city;
  }

  set city(city: string) {
    this._city = city;
  }

  get email(): string {
    return this._email;
  }

  set email(email: string) {
    this._email = email;
  }

  get availableForCollect(): boolean {
    return this._availableForCollect;
  }

  set availableForCollect(availableForCollect: boolean) {
    this._availableForCollect = availableForCollect;
  }

  get availableForDelivery(): boolean {
    return this._availableForDelivery;
  }

  set availableForDelivery(availableForDelivery: boolean) {
    this._availableForDelivery = availableForDelivery;
  }

  get openingTimes(): Array<DfDayHoursActivityRange> {
    return this._openingTimes;
  }

  set openingTimes(openingTimes: Array<DfDayHoursActivityRange>) {
    this._openingTimes = openingTimes;
  }

  get pickingTimes(): Array<DfDayHoursActivityRange> {
    return this._pickingTimes;
  }

  set pickingTimes(pickingTimes: Array<DfDayHoursActivityRange>) {
    this._pickingTimes = pickingTimes;
  }

  get postalCode(): string {
    return this._postalCode;
  }

  set postalCode(postalCode: string) {
    this._postalCode = postalCode;
  }

  get notes(): string {
    return this._notes;
  }

  set notes(notes: string) {
    this._notes = notes;
  }

  get gpsCoordinates(): DfCoordinates {
    return this._gpsCoordinates;
  }

  set gpsCoordinates(gpsCoordinates: DfCoordinates) {
    this._gpsCoordinates = gpsCoordinates;
  }

  get properties(): Array<DfProperty> {
    return this._properties;
  }

  set properties(properties: Array<DfProperty>) {
    this._properties = properties;
  }

  get distance(): number {
    return this._distance;
  }

  set distance(distance: number) {
    this._distance = distance;
  }


}
