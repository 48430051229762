import "reflect-metadata";
import { Container } from "inversify";
import { CONTAINER_TYPES } from "./container-types";
import { Api } from "../api/api";
import { ApiInterface } from "../api/interfaces/api-interface";
import Service from "../../service/service";
import ServiceInterface from "../../service/service-interface";
import { Application } from "../setup/application";
import { ApplicationInterface } from "../setup/application-interface";

const container: Container = new Container();

container.bind<ApiInterface>(CONTAINER_TYPES.ApiInterface).to(Api);
container.bind<ServiceInterface>(CONTAINER_TYPES.ServiceInterface).to(Service);
container
  .bind<ApplicationInterface>(CONTAINER_TYPES.ApplicationInterface)
  .to(Application);

export { container };
