export default class DfPaging {
  private _numberOfElements: number;
  private _totalElements: number;
  private _totalPages: number;
  private _size: number;
  private _empty: boolean;
  private _first: boolean;
  private _last: boolean;
  private _number: number;

  constructor(numberOfElements: number, totalElements: number, totalPages: number, size: number, empty: boolean, first: boolean, last: boolean, number: number) {
    this._numberOfElements = numberOfElements;
    this._totalElements = totalElements;
    this._totalPages = totalPages;
    this._size = size;
    this._empty = empty;
    this._first = first;
    this._last = last;
    this._number = number;
  }

  get numberOfElements(): number {
    return this._numberOfElements;
  }

  set numberOfElements(numberOfElements: number) {
    this._numberOfElements = numberOfElements;
  }

  get totalElements(): number {
    return this._totalElements;
  }

  set totalElements(totalElements: number) {
    this._totalElements = totalElements;
  }

  get totalPages(): number {
    return this._totalPages;
  }

  set totalPages(totalPages: number) {
    this._totalPages = totalPages;
  }

  get size(): number {
    return this._size;
  }

  set size(size: number) {
    this._size = size;
  }

  get empty(): boolean {
    return this._empty;
  }

  set empty(empty: boolean) {
    this._empty = empty;
  }

  get first(): boolean {
    return this._first;
  }

  set first(first: boolean) {
    this._first = first;
  }

  get last(): boolean {
    return this._last;
  }

  set last(last: boolean) {
    this._last = last;
  }

  get number(): number {
    return this._number;
  }

  set number(number: number) {
    this._number = number;
  }
}
