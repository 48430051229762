import Vuex, { StoreOptions } from "vuex";
import Vue from "vue";
import * as Es6Promise from "es6-promise";
import StoreStateInterface from "./store-state-interface";
import storeState from "./store-state";
import storeActions from "./store-actions";
import storeMutations from "./store-mutations";
import storeGetters from "./store-getters";

Es6Promise.polyfill();
Vue.use(Vuex);

const storeConfig: StoreOptions<StoreStateInterface> = {
  state: storeState,
  actions: storeActions,
  mutations: storeMutations,
  getters: storeGetters,
};

const store: any = new Vuex.Store<StoreStateInterface>(storeConfig);

export default store;
