import "intersection-observer";
import Vue from "vue";
import VueI18n from "vue-i18n";
import VueRouter from "vue-router";
import { CONTAINER_TYPES } from "../ioc/container-types";
import dictionary from "../internationalization/messages";
import Router from "../router/router";
import Store from "./../store/store";
import { ApplicationInterface } from "./../setup/application-interface";
import { injectable, inject } from "inversify";
import ServiceInterface from "./../../service/service-interface";
import PortalVue from "portal-vue";
import "@fortawesome/fontawesome-free/css/all.css";
import VueLazyload from "vue-lazyload";
import Vue2TouchEvents from "vue2-touch-events";
import VueTippy, { TippyComponent } from "vue-tippy";
import Clipboard from "v-clipboard";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfBrand from "@/vue/domain/brand/df-brand";

@injectable()
class Application implements ApplicationInterface {
  @inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  static readonly APPLICATION_ROOT_NODE_SELECTOR: string = "#app";
  static readonly APPLICATION_DEFAULT_LOCALE: string = "it";

  private i18n: VueI18n = null;
  private appRouter: VueRouter = null;

  public run() {
    const applicationRootNode: null | HTMLDivElement = <HTMLDivElement>Application.applicationRootNode();
    if (applicationRootNode != null) {
      this.init();
      this.internationalizationSetup();
      this.routerSetup();
      this.startApplication({});
    } else {
      alert("Application root missing");
    }
  }

  private static applicationRootNode(): null | Element {
    const applicationRootNode: null | Element = document.querySelector(Application.APPLICATION_ROOT_NODE_SELECTOR);
    return applicationRootNode;
  }

  private init() {
    Vue.use(VueI18n);
    Vue.use(VueRouter);
    Vue.use(PortalVue);
    Vue.use(Vue2TouchEvents);
    Vue.use(VueLazyload, {
      preLoad: 1.3,
      error: `${Utils.getPublicPath()}/assets/image-not-available.png`,
      loading: `${Utils.getPublicPath()}/assets/preview-loading.gif`,
      attempt: 1,
      observer: true,
    });
    Vue.use(VueTippy);
    Vue.component("tippy", TippyComponent);
    Vue.use(Clipboard);
  }

  private internationalizationSetup(): void {
    dictionary[Application.APPLICATION_DEFAULT_LOCALE] = Object.assign({}, dictionary[Application.APPLICATION_DEFAULT_LOCALE], dictionary[`${Application.APPLICATION_DEFAULT_LOCALE}_${Utils.getProfileName()}`] || {});
    this.i18n = new VueI18n({
      locale: Application.APPLICATION_DEFAULT_LOCALE,
      messages: dictionary,
    });
  }

  private routerSetup(): void {
    const routingMode: any = Utils.getRoutingMode();
    this.appRouter = Router(routingMode);
    this.appRouter.beforeEach((to: any, from: any, next: any) => {
      const documentTitle: string = to.meta.title ? this.i18n.t(to.meta.title).toString() : null;
      const brand: DfBrand = Store.getters.brand;
      if (brand) {
        const brandName: string = Utils.getPropertyValue(brand, Utils.PROPERTY_BRAND_NAME, "STRING", brand.description);
        document.title = documentTitle ? `${documentTitle} | ${brandName}` : "";
      } else {
        document.title = documentTitle ? documentTitle : "";
      }
      if (to.name && !to.name.includes("-no-store")) {
        //const lastPageName: string = Store.getters.history.length ? Store.getters.history[Store.getters.history.length - 1].name : null;
        //if (lastPageName !== to.name) {
          const history: Array<any> = Store.getters.history.length === Store.getters.historyIndex ? [...Store.getters.history, to] : Store.getters.history.map((story: any, index: number) => (Store.getters.historyIndex === index ? to : story));
          Store.dispatch("setHistory", history);
          Store.dispatch("setHistoryIndex", Store.getters.historyIndex + 1);
        //}
      }
      next();
    });
  }

  private startApplication(configParams: any) {
    const ApplicationComponent = () => import(/* webpackChunkName: 'application' */ "./../../components/application.vue");
    const VueApp: Vue = new Vue({
      el: "#app",
      store: Store,
      router: this.appRouter,
      i18n: this.i18n,
      render: (h) =>
        h(ApplicationComponent, {
          props: {
            accountEnabled: configParams.accountEnabled,
            passwordEnabled: configParams.passwordEnabled,
          },
        }),
      provide: {
        [CONTAINER_TYPES.ServiceInterface]: this.service,
      },
    });
  }
}

export { Application };
