import DfProperty from "@/vue/domain/content/df-property";

export default class DfUser {
  private _logged: boolean = null;
  private _firstName: string = null;
  private _lastName: string = null;
  private _email: string = null;
  private _properties: Array<DfProperty>;

  constructor(logged: boolean, email: string, properties: Array<DfProperty>) {
    this._logged = logged;
    this._email = email;
    this._properties = properties;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(firstName: string) {
    this._firstName = firstName;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(lastName: string) {
    this._lastName = lastName;
  }

  get email(): string {
    return this._email;
  }

  set email(email: string) {
    this._email = email;
  }

  get properties(): Array<DfProperty> {
    return this._properties;
  }

  set properties(properties: Array<DfProperty>) {
    this._properties = properties;
  }

}
